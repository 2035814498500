import moment from "moment";
import FormEdit from "./steps/FormEdit.vue";

import { sanitise } from "@/util/api";
import { Project, AddDialogAttributes } from "@/types";

import Confirm from "@/apps/office/containers/table/shared/dialogs/steps/Confirm.vue";

import WithSteps from "@/apps/office/containers/table/shared/dialogs/with-steps";

type FormData = {
  item: Partial<Project>;
  formValid: boolean;
};

export default WithSteps<FormData, AddDialogAttributes>({
  title: "office.dialogs.projects.edit.title",

  maxWidth: 600,

  formData() {
    return {
      item: {
        region: "",
        projectYear: moment().get("year"),
        year: moment().get("year"),
        startAt: null,
        endAt: null,
        duration: null,
        type: 0,
        buildingType: 0,
        installationType: 0,
        status: 0,
        expectedHouses: 0,
        conversion: 100,
        percentageSlantedRoof: 1,
        averageAmountPanels: 0,
        panelTypeId: null,
        inverterConfigurationId: null,
        zipcodeTwoArea: 0,
        zmCustomer: "",
        projectCertainty: "maybe",
        planningType: "fixed",
        inverterType: "micro",
        powerMainsType: "1-phase"
      },

      formValid: false
    };
  },

  steps: [
    {
      component: FormEdit,

      isValid() {
        return this.$data.formValid;
      },

      props() {
        return {
          item: this.$data.item
        };
      },

      value: {
        get() {
          return this.$data.formValid;
        },

        set(isValid: boolean) {
          this.$data.formValid = isValid;
        }
      }
    },

    {
      component: Confirm,

      value: {
        get() {
          return this.$t("office.dialogs.projects.save");
        }
      },

      labels: {
        action: "dialogs.save"
      },

      async afterStep() {
        const payload = sanitise(this.$data.item);

        const item: Project = await this.$store.dispatch(
          "projects/item:create",
          {
            item: payload
          }
        );

        const proposition = this.$store.state.route.name;

        if (!proposition) return;

        const installationType = this.$store.getters[
          "projects/currentProposition"
        ](proposition);

        await this.$store.dispatch(`projects/${proposition}/fetch:all:page`, {
          params: {
            installationType:
              proposition != "allprojects" ? installationType : null
          }
        });
      }
    }
  ]
});
