






















































































































































































































































































import Vue from "vue";
import _ from "underscore";
import { ListItem, HousingCooperative, Installer } from "@/types";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  props: {
    filters: {
      type: Object as () => Record<string, any>
    },

    params: {
      type: Object as () => Record<string, any>
    },

    readonly: {
      type: Object as () => Record<string, true>
    },

    limit: {
      type: Object as () => Record<string, number[]>
    },

    setParam: {
      type: Function
    },

    clearParam: {
      type: Function
    }
  },

  computed: {
    projectTypes(): ListItem[] {
      const states: ListItem[] = this.$t(
        "office.dictionaries.projectTypes"
      ) as any;

      if (!this.limit.type) return states;

      return states
        .filter(
          state =>
            typeof state.value === "number" &&
            this.limit.type.includes(state.value)
        )
        .map(state => ({
          ...state,
          disabled:
            this.params.type?.length === 1 &&
            this.params.type.includes(state.value)
        }));
    },

    installationTypes(): ListItem[] {
      let types: ListItem[] = this.$t(
        "office.dictionaries.installationTypes"
      ) as any;

      if (!this.limit.installationType) return types;

      return types
        .filter(
          type =>
            typeof type.value === "number" &&
            this.limit.installationType?.includes(type.value)
        )
        .map(type => ({
          ...type,
          disabled: true
        }));
    },

    housingCooperatives(): HousingCooperative[] {
      return _.sortBy(
        this.$store.state.dictionaries.housingCooperatives.items,
        "name"
      );
    },

    projectManagers(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.projectManagers.items,
        "name"
      );
    },

    accountManagers(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.accountManagers.items,
        "name"
      );
    },

    installers(): Installer[] {
      return _.sortBy(this.$store.state.dictionaries.installers.items, "name");
    },

    phaseStatuses(): TranslateResult {
      return this.$t("office.dictionaries.phaseStatuses");
    },

    zonmaatCustomers(): unknown[] {
      const items = (this.$t(
        "office.dictionaries.zonmaatCustomers"
      ) as unknown) as { text: string; value: string }[];
      return items.map(({ text, value }) => ({ id: value, name: text }));
    },

    zonmaatProjectManagers(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.zonmaatProjectManagers.items,
        "name"
      );
    },

    zonmaatPlanners(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.zonmaatPlanners.items,
        "name"
      );
    },

    zonmaatForemans(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.zonmaatForemans.items,
        "name"
      );
    },

    projectStatus(): any[] {
      const states: ListItem[] = this.$t(
        "office.dictionaries.projectStatus"
      ) as any;

      if (!this.limit.status) return states;

      return states
        .filter(
          state =>
            typeof state.value === "number" &&
            this.limit.status.includes(state.value)
        )
        .map(state => ({
          ...state,
          disabled:
            this.params.status?.length === 1 &&
            this.params.status.includes(state.value)
        }));
    }
  }
});
