











































































































import Vue, { PropType } from "vue";
import { Project, Panel, InverterConfiguration } from "@/types";
import InputPercentage from "@/components/inputs/InputPercentage.vue";
import InputNumber from "@/components/inputs/InputNumber.vue";
import { TranslateResult } from "vue-i18n";
import { $t } from "@/apps/office/i18n";

export default Vue.extend({
  components: {
    InputPercentage,
    InputNumber
  },
  props: {
    value: {
      type: Object as PropType<Project>
    },
    rules: {
      type: Object,
      default: () => []
    },
    autofocus: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      invertConfigurationItems: [] as InverterConfiguration[],
      panelItems: [] as Panel[]
    };
  },

  created() {
    this.getAvailableInverterConfigurationItems();
    this.getAvailablePanelItems();
  },

  methods: {
    calculateMaxRoofPercentage(val: number) {
      if (val > 100) val = 100;
      if (val < 0) val = 0;

      return val / 100;
    },
    getAvailableInverterConfigurationItems(): void {
      const inverterConfigurations: InverterConfiguration[] = this.$store.state
        .dictionaries.inverterConfigurations.items;

      const defaultInverterConfiguration = inverterConfigurations
        ?.filter(item => !!item.default)
        ?.map((item: InverterConfiguration) => ({
          ...item,
          id: null,
          name:
            this.value.zmCustomer === "wocozon"
              ? `Standaard (${item.name?.split(" ")?.[0]})`
              : "",
          disabled: false
        }));

      const selectInvertConfigurationItems = inverterConfigurations
        ?.filter(
          (item: InverterConfiguration) =>
            item.available === 1 ||
            item.id === this.value?.inverterConfigurationId
        )
        .map((item: InverterConfiguration) => ({
          ...item,
          disabled:
            item.id === this.value?.inverterConfigurationId && !item.available
        }));

      this.invertConfigurationItems = [
        defaultInverterConfiguration[0],
        ...selectInvertConfigurationItems
      ];
    },
    getAvailablePanelItems(): void {
      const panels: Panel[] = this.$store.state.dictionaries.panels.items;

      const defaultPanel = panels
        ?.filter(item => item.default)
        ?.map((item: Panel) => ({
          ...item,
          id: null,
          model:
            this.value.zmCustomer === "wocozon"
              ? `Standaard (${item.brand} ${item.output} Wp)`
              : "",
          disabled: false
        }));

      const selectPanels = panels
        .filter(
          (item: Panel) =>
            item.available === 1 || item.id === this.value?.panelTypeId
        )
        .map((item: Panel) => ({
          ...item,
          model: `${item.brand} ${item.output} Wp`,
          disabled: item.id === this.value?.panelTypeId && !item.available
        }));

      this.panelItems = [defaultPanel[0], ...selectPanels];
    }
  },

  computed: {
    averageAmountPanels: {
      get(): number {
        return this.value.averageAmountPanels ?? 0;
      },
      set(val: number) {
        if (val < 0) val = 0;

        this.value.averageAmountPanels = Number(val.toFixed(0));
      }
    },
    slantedRoofPercentage: {
      get(): number {
        return Number((this.value.percentageSlantedRoof * 100).toFixed(2));
      },

      set(val: number) {
        val = this.calculateMaxRoofPercentage(val);
        this.value.percentageSlantedRoof = val;
      }
    },
    flatRoofPercentage: {
      get(): number {
        return 100 - this.slantedRoofPercentage;
      },

      set(val: number) {
        this.slantedRoofPercentage = 100 - val;
      }
    },
    getPanelInputHint(): TranslateResult | string {
      if (this.value.panelTypeId === null) {
        return $t("office.labels.hints.defaultPanelSelected");
      }
      return $t("office.labels.hints.lockedPanelSelected");
    },
    getInverterConfigurationInputHint(): TranslateResult | string {
      if (this.value?.inverterConfigurationId === null) {
        return $t("office.labels.hints.defaultInverterConfigurationSelected");
      }
      return $t("office.labels.hints.lockedInverterConfigurationSelected");
    }
  }
});
