import { Column } from "@/types";

export default [
  {
    text: "office.tables.headers.ID",
    title: "office.tables.headers.id",
    align: "left",
    sortable: true,
    value: "id",
    width: 50
  },
  { value: "region", text: `office.tables.headers.region`, hidden: true },
  { value: "urlRegion", text: `office.tables.headers.region`, sortable: true },
  {
    value: "createdAt",
    text: `office.tables.headers.createdAt`,
    sortable: true
  },
  {
    value: "updatedAt",
    text: `office.tables.headers.updatedAt`,
    sortable: true
  },
  {
    value: "startAt",
    text: `office.tables.headers.startAt`,
    hint: `office.tables.hints.startAt`,
    sortable: true
  },
  {
    value: "planned.startAt",
    text: `office.tables.headers.startAt`,
    hint: `office.tables.hints.startAt`,
    sortable: true,
    hidden: true
  },
  {
    value: "active.startAt",
    text: `office.tables.headers.startAt`,
    hint: `office.tables.hints.startAt`,
    sortable: true,
    hidden: true
  },
  {
    value: "endAt",
    text: `office.tables.headers.endAt`,
    hint: `office.tables.hints.endAt`,
    sortable: true
  },
  {
    value: "planned.endAt",
    text: `office.tables.headers.endAt`,
    hint: `office.tables.hints.endAt`,
    sortable: true,
    hidden: true
  },
  {
    value: "active.endAt",
    text: `office.tables.headers.endAt`,
    hint: `office.tables.hints.endAt`,
    sortable: true,
    hidden: true
  },
  {
    value: "duration",
    text: `office.tables.headers.duration`,
    hint: `office.tables.hints.duration`,
    sortable: true
  },
  {
    value: "planned.duration",
    text: `office.tables.headers.duration`,
    hint: `office.tables.hints.duration`,
    sortable: true,
    hidden: true
  },
  {
    value: "active.duration",
    text: `office.tables.headers.duration`,
    hint: `office.tables.hints.duration`,
    sortable: true,
    hidden: true
  },
  {
    value: "expectedHouses",
    text: `office.tables.headers.expectedHouses`,
    hint: `office.tables.hints.expectedHouses`,
    sortable: true
  },
  {
    value: "installationsCount",
    text: `office.tables.headers.installationsCount`,
    hint: `office.tables.hints.installationsCount`,
    sortable: false
  },
  {
    value: "workQueue",
    text: `office.tables.headers.workQueue`,
    hint: `office.tables.hints.workQueue`,
    sortable: false
  },
  {
    value: "expectedWorkQueue",
    text: `office.tables.headers.expectedWorkQueue`,
    hint: `office.tables.hints.expectedWorkQueue`,
    sortable: false
  },
  {
    value: "expectedInstallations",
    text: `office.tables.headers.expectedInstallations`,
    hint: `office.tables.hints.expectedInstallations`,
    sortable: true
  },
  {
    value: "planned.expectedInstallations",
    text: `office.tables.headers.expectedInstallations`,
    hint: `office.tables.hints.expectedInstallations`,
    sortable: true,
    hidden: true
  },
  {
    value: "active.expectedInstallations",
    text: `office.tables.headers.expectedInstallations`,
    hint: `office.tables.hints.expectedInstallations`,
    sortable: true,
    hidden: true
  },
  {
    value: "finished.expectedInstallations",
    text: `office.tables.headers.expectedInstallations`,
    hint: `office.tables.hints.expectedInstallationsPlanned`,
    sortable: true,
    hidden: true
  },
  {
    value: "conversion",
    text: `office.tables.headers.conversion`,
    hint: `office.tables.hints.conversion`,
    sortable: true
  },
  {
    value: "notConvertedYet",
    text: `office.tables.headers.pendingConversion`,
    hint: `office.tables.hints.pendingConversion`,
    sortable: true
  },
  {
    value: "planned.conversion",
    text: `office.tables.headers.conversion`,
    hint: `office.tables.hints.conversion`,
    sortable: true,
    hidden: true
  },
  {
    value: "active.conversion",
    text: `office.tables.headers.conversion`,
    hint: `office.tables.hints.conversion`,
    sortable: true,
    hidden: true
  },
  {
    value: "finished.conversion",
    text: `office.tables.headers.conversion`,
    hint: `office.tables.hints.conversionPlanned`,
    sortable: true,
    hidden: true
  },
  {
    value: "actualConversion",
    text: `office.tables.headers.actualConversion`,
    hint: `office.tables.hints.actualConversion`,
    sortable: true
  },
  {
    value: "contractConversion",
    text: `office.tables.headers.contractConversion`,
    hint: `office.tables.hints.contractConversion`,
    sortable: true
  },
  {
    value: "webinarAt",
    text: `office.tables.headers.webinar`,
    hint: `office.tables.hints.webinar`,
    sortable: true
  },
  {
    value: "preAnnouncementAt",
    text: `office.tables.headers.preAnnouncement`,
    hint: `office.tables.hints.preAnnouncement`,
    sortable: true
  },
  {
    value: "contractsSentAt",
    text: `office.tables.headers.contractSent`,
    hint: `office.tables.hints.contractSent`,
    sortable: true
  },
  {
    value: "contractsDeadlineAt",
    text: `office.tables.headers.contractDeadline`,
    hint: `office.tables.hints.contractDeadline`,
    sortable: true
  },

  {
    value: "accountManagerName",
    text: `office.tables.headers.accountManager`,
    sortable: true
  },
  {
    value: "projectManagerName",
    text: `office.tables.headers.projectManager`,
    sortable: true
  },
  { value: "code", text: `office.tables.headers.projectCode`, sortable: true },
  { value: "year", text: `office.tables.headers.projectYear`, sortable: true },
  {
    value: "sequence",
    text: `office.tables.headers.sequenceNumber`,
    sortable: true
  },
  { value: "status", text: "office.tables.headers.status", sortable: true },
  { value: "type", text: `office.tables.headers.projectType`, sortable: true },
  {
    value: "buildingType",
    text: `office.tables.headers.buildingType`,
    sortable: true
  },
  {
    value: "installationType",
    text: `office.tables.headers.installationType`,
    sortable: true
  },
  {
    value: "housingCooperativeName",
    text: `office.tables.headers.housingCooperative`,
    sortable: true
  },
  {
    value: "installerName",
    text: `office.tables.headers.installer`,
    sortable: true
  },
  {
    value: "zmSubcontractorGroups",
    text: `office.tables.headers.zm_subcontractor_groups`,
    sortable: false,
    hidden: true
  },
  {
    value: "zmCustomer",
    text: `office.tables.headers.zm_customer`,
    sortable: true
  },
  {
    value: "planningType",
    text: `office.tables.headers.planning_type`,
    sortable: false
  },
  {
    value: "inverterType",
    text: `office.tables.headers.inverter_type`,
    sortable: false,
    hidden: true
  },
  {
    value: "powerMainsType",
    text: `office.tables.headers.power_mains_type`,
    sortable: false,
    hidden: true
  },
  {
    value: "zmRequiredExpertise",
    text: `office.tables.headers.zm_required_expertise`,
    sortable: false,
    hidden: true
  },
  {
    value: "zmProjectManagerName",
    text: `office.tables.headers.zm_project_manager`,
    sortable: false
  },
  {
    value: "zmForemanName",
    text: `office.tables.headers.zm_foreman`,
    sortable: false
  },
  {
    value: "zmPlannerName",
    text: `office.tables.headers.zm_project_planner`,
    sortable: false
  },

  {
    value: "zmScreeningReady",
    text: `office.tables.headers.zm_screening_ready`,
    sortable: false,
    hidden: true
  },
  {
    value: "zmBlueprintReady",
    text: `office.tables.headers.zm_blueprint_ready`,
    sortable: false,
    hidden: true
  },
  {
    value: "zmQuoteReady",
    text: `office.tables.headers.zm_quote_ready`,
    sortable: false,
    hidden: true
  },
  {
    value: "zmWiringDiagramReady",
    text: `office.tables.headers.zm_wiring_diagram_ready`,
    sortable: false,
    hidden: true
  },
  {
    value: "zmVgmTraReady",
    text: `office.tables.headers.zm_vgm_tra_ready`,
    sortable: false,
    hidden: true
  },
  {
    value: "zmProjectPlanReady",
    text: `office.tables.headers.zm_project_plan_ready`,
    sortable: false,
    hidden: true
  },
  {
    value: "zmHandoverReady",
    text: `office.tables.headers.zm_handover_ready`,
    sortable: false,
    hidden: true
  },
  {
    value: "urlProjectFiles",
    text: `office.tables.headers.url_project_files`,
    sortable: false,
    hidden: true
  },
  {
    value: "projectCertainty",
    text: `office.tables.headers.project_certainty`,
    sortable: false
  },
  {
    value: "tenantMeetingDate",
    text: `office.tables.headers.physical_residents_meeting`,
    sortable: false,
    hidden: true
  },
  {
    value: "housesCount",
    text: `office.tables.headers.houses`,
    width: 75,
    align: "right",
    sortable: false
  },
  {
    value: "housesCountFinal",
    text: `office.tables.headers.houses`,
    sortable: false,
    hidden: true
  },
  {
    value: "teamsUrl",
    text: `office.tables.headers.projectWebpage`,
    sortable: false
  },
  {
    value: "preAnnouncement",
    text: `office.tables.headers.preAnnouncement`,
    sortable: false,
    hidden: true
  },
  {
    value: "contractSent",
    text: `office.tables.headers.contractSent`,
    sortable: false,
    hidden: true
  },
  {
    value: "contractDeadline",
    text: `office.tables.headers.contractDeadline`,
    sortable: false,
    hidden: true
  },
  {
    value: "panelTypeLabel",
    text: `office.tables.headers.panelType`,
    sortable: false
  },
  {
    value: "inverterConfigurationLabel",
    text: `office.tables.headers.inverterConfiguration`,
    sortable: false
  },
  {
    value: "screeningStartDate",
    text: `office.tables.headers.screeningStartDate`,
    sortable: false
  },
  {
    value: "NotInProcess",
    text: "office.dictionaries.projectStates[0].text",
    title: "office.dictionaries.projectStates[0].text",
    hint: "office.dictionaries.projectStates[0].text",
    width: 75
  },
  {
    value: "P200",
    text: "P200",
    title: "office.dictionaries.projectStates[1].text",
    hint: "office.dictionaries.projectStates[1].text",
    width: 75
  },
  {
    value: "P210",
    text: "P210",
    title: "office.dictionaries.projectStates[2].text",
    hint: "office.dictionaries.projectStates[2].text",
    width: 75
  },
  {
    value: "P220",
    text: "P220",
    title: "office.dictionaries.projectStates[3].text",
    hint: "office.dictionaries.projectStates[3].text",
    width: 75
  },
  {
    value: "P221",
    text: "P221",
    title: "office.dictionaries.projectStates[4].text",
    hint: "office.dictionaries.projectStates[4].text",
    width: 75
  },
  {
    value: "P222",
    text: "P222",
    title: "office.dictionaries.projectStates[5].text",
    hint: "office.dictionaries.projectStates[5].text",
    width: 75
  },
  {
    value: "P230",
    text: "P230",
    title: "office.dictionaries.projectStates[6].text",
    hint: "office.dictionaries.projectStates[6].text",
    width: 75
  },
  {
    value: "P240",
    text: "P240",
    title: "office.dictionaries.projectStates[7].text",
    hint: "office.dictionaries.projectStates[7].text",
    width: 75
  },
  {
    value: "P250",
    text: "P250",
    title: "office.dictionaries.projectStates[8].text",
    hint: "office.dictionaries.projectStates[8].text",
    width: 75
  },
  {
    value: "P297",
    text: "P297",
    title: "office.dictionaries.projectStates[9].text",
    hint: "office.dictionaries.projectStates[9].text",
    width: 75
  },
  {
    value: "P298",
    text: "P298",
    title: "office.dictionaries.projectStates[10].text",
    hint: "office.dictionaries.projectStates[10].text",
    width: 75
  },
  {
    value: "P299",
    text: "P299",
    title: "office.dictionaries.projectStates[11].text",
    hint: "office.dictionaries.projectStates[11].text",
    width: 75
  },
  {
    value: "planned.installationStartAndEndDate",
    text: "office.labels.periods.installationPeriod",
    hidden: true
  },
  {
    value: "planned.screeningStartAndEndDate",
    text: "office.labels.periods.screeningPeriod",
    hidden: true
  },
  {
    value: "planned.conversionStartAndEndDate",
    text: "office.labels.periods.conversionPeriod",
    hidden: true
  },
  {
    value: "planned.planningStartAndEndDate",
    text: "office.labels.periods.planningPeriod",
    hidden: true
  },
  {
    value: "active.installationStartAndEndDate",
    text: "office.labels.periods.installationPeriod",
    hidden: true
  },
  {
    value: "active.screeningStartAndEndDate",
    text: "office.labels.periods.screeningPeriod",
    hidden: true
  },
  {
    value: "active.conversionStartAndEndDate",
    text: "office.labels.periods.conversionPeriod",
    hidden: true
  },
  {
    value: "active.planningStartAndEndDate",
    text: "office.labels.periods.planningPeriod",
    hidden: true
  },
  {
    value: "finished.installationStartAndEndDate",
    text: "office.labels.periods.installationPeriod",
    hidden: true
  },
  {
    value: "finished.screeningStartAndEndDate",
    text: "office.labels.periods.screeningPeriod",
    hidden: true
  },
  {
    value: "finished.conversionStartAndEndDate",
    text: "office.labels.periods.conversionPeriod",
    hidden: true
  },
  {
    value: "finished.planningStartAndEndDate",
    text: "office.labels.periods.planningPeriod",
    hidden: true
  }
] as Column[];
