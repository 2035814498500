










































































































































































import Vue from "vue";

import StatusOverview from "./containers/StatusOverview.vue";
import ProjectInfo from "./containers/ProjectInfo.vue";
import EditDialog from "@/apps/office/containers/table/projects/dialogs/Edit";
import StatusPlanned from "./containers/StatusPlanned.vue";
import StatusActive from "./containers/StatusActive.vue";
import StatusFinished from "./containers/StatusFinished.vue";
import ProjectsForecast from "@/apps/office/containers/charts/projects-forecast";
import { getForecastWeeks } from "@/apps/office/util/planning";

import { ForecastOverview, Project } from "@/types";
import Gmap from "@/components/gmap/Gmap.vue";
import projectPropositions from "@/apps/office/static/project-propositions.json";

export default Vue.extend({
  components: {
    StatusOverview,
    StatusPlanned,
    StatusActive,
    StatusFinished,
    ProjectInfo,
    EditDialog,
    ProjectsForecast,
    Gmap
  },

  created() {
    this.$store.dispatch("dictionaries/panels/fetch");
    this.$store.dispatch("dictionaries/inverterConfigurations/fetch");
  },

  data() {
    return {
      showDialog: false,
      autofocus: "region",
      forecast: {
        planned: [],
        expectedWorkQueue: [],
        workQueue: [],
        distributedWorkQueue: [],
        finished: [],
        capacity: [],
        targets: []
      } as ForecastOverview,

      details: [
        "code",
        "installerName",
        "status",
        "screeningStartedAt",
        "webinarAt",
        "preAnnouncementAt",
        "contractsSentAt",
        "contractsDeadlineAt"
      ],

      general: [
        "zmCustomer",
        "housingCooperativeName",
        "code",
        "region",
        "type",
        "installationType",
        "buildingType",
        "teamsUrl",
        "urlProjectFiles"
      ],

      description: ["description"],

      projectTeam: [
        "accountManagerName",
        "projectManagerName",
        "installerName",
        "zmSubcontractorGroups",
        "zmRequiredExpertise",
        "zmProjectManagerName",
        "zmForemanName",
        "zmPlannerName"
      ],

      screening: [
        "zmScreeningReady",
        "zmBlueprintReady",
        "zmQuoteReady",
        "zmWiringDiagramReady",
        "zmVgmTraReady",
        "zmProjectPlanReady",
        "zmHandoverReady"
      ],

      conversion: [
        "webinarAt",
        "tenantMeetingDate",
        "preAnnouncementAt",
        "contractsSentAt",
        "contractsDeadlineAt",
        "actualConversion",
        "contractConversion"
      ],

      materials: [
        "panelTypeLabel",
        "inverterConfigurationLabel",
        "inverterType",
        "powerMainsType"
      ],

      projectUpdates: ["createdAt", "updatedAt"]
    };
  },

  computed: {
    item(): Project {
      return this.$store.getters["projects/details"](
        this.$router.currentRoute.params.id
      );
    },

    isEditableProject(): boolean {
      if (!this.$role("zonmaat_office")) {
        return this.item.zmCustomer === "wocozon";
      } else {
        return true;
      }
    },

    planningItems(): string[] {
      switch (this.item?.status) {
        case 0:
          return [
            "planned.screeningStartAndEndDate",
            "planned.conversionStartAndEndDate",
            "planned.planningStartAndEndDate",
            "planned.installationStartAndEndDate",
            "planningType",
            "averageHoursElectrician",
            "averageHoursPvMechanic"
          ];
        case 1:
          return [
            "active.screeningStartAndEndDate",
            "active.conversionStartAndEndDate",
            "active.planningStartAndEndDate",
            "active.installationStartAndEndDate",
            "planningType",
            "averageHoursElectrician",
            "averageHoursPvMechanic"
          ];
        case 2:
          return [
            "finished.screeningStartAndEndDate",
            "finished.conversionStartAndEndDate",
            "finished.planningStartAndEndDate",
            "finished.installationStartAndEndDate",
            "planningType"
          ];
        default:
          return [];
      }
    },

    wocozoneUrl(): string {
      return this.$store.state.axiosVariables.wocozoneUrl;
    },

    weeks(): string[] {
      return getForecastWeeks(this.forecast);
    },
    installationName(): string {
      if (this.item) {
        const installationName = projectPropositions.find(
          proposition =>
            proposition.installation_type === this.item?.installationType
        )?.installation_name;
        if (installationName) {
          return installationName;
        }
      }
      return "regularInstallation";
    },
    projectName(): string {
      if (this.item) {
        const projectName = projectPropositions.find(
          proposition =>
            proposition.installation_type === this.item?.installationType
        )?.project_name;
        if (projectName) {
          return projectName;
        }
      }
      return "regular";
    },
    mapConfig(): any {
      if (this.item) {
        return {
          resource: `installations/${this.installationName}`,
          settings: `installations/${this.installationName}/settings`,
          params: {
            projectId: this.item.id
          },
          filters: {
            projectState: true
          },
          link: `/installaties/${this.projectName}?projectId=${this.item.id}#map`
        };
      }
      return { link: `/installaties/regular#map` };
    }
  },

  async mounted() {
    await this.getForecast();
  },

  methods: {
    handleEdit(field: string = "region") {
      this.autofocus = field;
      this.showDialog = true;

      this.$store.dispatch(`projects/dictionaries/fetch`);
    },

    async getForecast() {
      this.forecast.planned = await this.$store.dispatch(
        "planning/forecast/fetch",
        {
          type: "planned-expected",
          id: this.$router.currentRoute.params.id
        }
      );

      this.forecast.expectedWorkQueue = await this.$store.dispatch(
        "planning/forecast/fetch",
        {
          type: "active-expected",
          id: this.$router.currentRoute.params.id
        }
      );

      this.forecast.workQueue = await this.$store.dispatch(
        "planning/forecast/fetch",
        {
          type: "active-with-date",
          id: this.$router.currentRoute.params.id
        }
      );

      this.forecast.distributedWorkQueue = await this.$store.dispatch(
        "planning/forecast/fetch",
        {
          type: "active-without-date",
          id: this.$router.currentRoute.params.id
        }
      );

      this.forecast.finished = await this.$store.dispatch(
        "planning/forecast/fetch",
        {
          type: "active-installations",
          id: this.$router.currentRoute.params.id
        }
      );
    },
    async resetFilters(routeUrl: string) {
      const lastUsed = this.$store.getters[
        `installations/${this.installationName}/settings/get`
      ].lastUsed;
      const resetLastUsed = Object.assign(lastUsed, {
        searchTerm: "",
        params: { projectId: this.$router.currentRoute.params.id }
      });
      await this.$store.commit(
        `installations/${this.installationName}/settings/set:lastUsed`,
        resetLastUsed
      );
      await this.$router.push(routeUrl);
    }
  }
});
