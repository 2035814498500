import FormEdit from "./steps/FormEdit.vue";
import { sanitise } from "@/util/api";

import { Project, EditDialogAttributes } from "@/types";

import WithSteps from "@/apps/office/containers/table/shared/dialogs/with-steps";

type FormData = {
  item: Project;
  formValid: boolean;
};

type DialogAttrs = EditDialogAttributes<Project> & { autofocus: string };

export default WithSteps<FormData, DialogAttrs>({
  title: "office.dialogs.projects.edit.title",

  maxWidth: 600,

  formData() {
    return {
      item: this.$attrs.item,

      formValid: false
    };
  },

  steps: [
    {
      component: FormEdit,

      isValid() {
        return this.$data.formValid;
      },

      props() {
        return {
          item: this.$data.item,
          autofocus: this.$attrs.autofocus,
          isEditing: true
        };
      },

      value: {
        get() {
          return this.$data.formValid;
        },

        set(isValid: boolean) {
          this.$data.formValid = isValid;
        }
      },

      labels: {
        action: "dialogs.save"
      },

      async afterStep() {
        const payload = sanitise(this.$data.item);
        await this.$store.dispatch("projects/item:update", payload);

        await this.$store.dispatch(
          `projects/regular/fetch`,
          this.$data.item.id
        );
      }
    }
  ]
});
