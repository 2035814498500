
















































import Vue, { VueConstructor } from "vue";
import {
  Pagination,
  TableSettings,
  TablePreset,
  Column,
  Nullable,
  TableAction,
  CellRenderers,
  TableSortOptions
} from "@/types";
import _ from "underscore";

export default Vue.extend({
  props: {
    resource: {
      type: String,
      default: () => ""
    },

    readonly: {
      type: Object as () => Record<string, any>
    },

    filters: {
      type: Object as () => Record<string, boolean>
    },

    settings: {
      type: String
    },

    relationId: {
      type: String,
      default: undefined
    },

    actions: {
      type: Array as () => TableAction[],
      default: () => []
    },

    renderers: {
      type: Object as () => CellRenderers
    }
  },

  data() {
    return {
      item: null as Nullable<unknown>,
      showEditDialog: false
    };
  },

  computed: {
    mainHeight(): number {
      return this.$store.state.ui.mainHeight;
    },

    dialogEdit(): TableAction | undefined {
      return this.actions.find(action => action.name === "edit");
    },

    allowEdit(): boolean {
      return !!this.dialogEdit && this.$permission(this.dialogEdit.permission);
    },

    propName() {
      return (slot: string) => slot.split(".").pop();
    },

    value() {
      return (item: any, slot: string) => {
        return _.property(slot.split(".").slice(1))(item);
      };
    },

    route() {
      return this.$store.state.route.path;
    },

    tableSettings(): TableSettings {
      return this.$store.getters[`${this.settings}/get`];
    },

    columns(): Column[] {
      const columns: Column[] = [
        ...this.$store.getters[`${this.settings}/columns:current`],
        { value: "actions", width: 100, align: "right", sortable: false }
      ];

      return columns;
    },

    columnStringArray(): string[] {
      return this.columns.flatMap(item => item.value);
    },

    preset(): TablePreset {
      return this.tableSettings.lastUsed;
    },

    currentPage: {
      get(): number {
        return this.tableSettings.currentPage;
      },

      set(val: number) {
        this.$store.commit(`${this.settings}/set:currentPage`, val);
      }
    },

    options: {
      get(): TableSortOptions | undefined {
        return this.tableSettings.sortOptions;
      },

      set(options: TableSortOptions) {
        this.$store.dispatch(`${this.settings}/set:sortOptions`, options);
      }
    },

    isFetching(): boolean {
      return this.$store.getters[`${this.resource}/isPending`];
    },

    lastSearch(): number {
      return this.tableSettings.lastSearch;
    },

    page(): Pagination<unknown> {
      const nodes: Pagination<unknown> = this.$store.getters[
        `${this.resource}/page`
      ](this.currentPage, this.relationId);

      return nodes;
    },

    perPage(): number {
      return this.page?.meta.per_page;
    },

    serverItems(): number {
      return this.page?.meta.total;
    },

    items(): unknown[] {
      return this.page?.data ?? [];
    }
  },

  methods: {
    search() {
      const params = _.pick(this.preset.params, Object.keys(this.filters));

      this.$store.dispatch(`${this.resource}/fetch:all:page`, {
        relationId: this.relationId,
        page: this.tableSettings.currentPage,
        perPage: this.tableSettings.perPage,
        params: { ...params, ...this.readonly },
        sort: this.options,
        query: this.preset.searchTerm
      });
    },

    handleEdit(item: unknown) {
      this.item = this.$clone(item);
      this.showEditDialog = true;
    }
  },

  watch: {
    route: {
      immediate: true,

      handler(val) {
        this.$store.commit(`${this.settings}/set:selected`, []);
        this.$store.commit(`${this.settings}/set:sortOptions`, {
          sortBy: [],
          sortDesc: [],
          multiSort: true
        } as TableSortOptions);
        this.currentPage = 1;

        this.$nextTick(() => {
          this.search();
        });
      }
    },

    showEditDialog(val: boolean): void {
      if (!val) {
        setTimeout(() => (this.item = null), 500);
      }
    },

    lastSearch() {
      this.search();
    },

    currentPage(page: number) {
      if (page > 1) {
        this.search();
      }
    }
  }
});
