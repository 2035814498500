export default {
  HOUSING_COOPERATIVE: "housingCooperativeId",
  ACCOUNT_MANAGER: "accountManagerUserId",
  PROJECT_MANAGER: "projectManagerId",
  PROJECT_STATUS: "status",
  PROJECT_TYPE: "type",
  BUILDING_TYPE: "buildingType",
  INSTALLATION_TYPE: "installationType",
  PROJECT_YEAR: "year",
  SEQUENCE_NUMBER: "sequenceNumber",
  SEQUENCE: "sequence",
  INSTALLER: "installerId",
  SELECTED: "selected",
  EXCLUDED: "excluded",
  DATE_START_AT_BEFORE: "startAtBefore",
  DATE_START_AT_AFTER: "startAtAfter",
  DATE_END_AT_BEFORE: "endDateBefore",
  DATE_END_AT_AFTER: "endDateAfter",
  CONTRACT_STATES: "contractStates",
  PROJECT_STATE: "projectState",
  PROJECT_STATES: "projectStates",
  METER_STATE: "meterState",
  PANELS_STATE: "panelsState",
  DATE_APPROVED_AFTER: "approvedAfter",
  DATE_APPROVED_BEFORE: "approvedBefore",
  DATE_METER_REGISTERED_AFTER: "registeredAfter",
  DATE_METER_REGISTERED_BEFORE: "registeredBefore",
  DATE_PLANNED_AFTER: "plannedAfter",
  DATE_PLANNED_BEFORE: "plannedBefore",
  DATE_METER_INSTALLED_BEFORE: "meterInstalledBefore",
  DATE_METER_INSTALLED_AFTER: "meterInstalledAfter",
  DATE_PANELS_INSTALLED_BEFORE: "panelsInstalledBefore",
  DATE_PANELS_INSTALLED_AFTER: "panelsInstalledAfter",
  DATE_RANGE_PLANNED: "plannedDateRange",
  DATE_RANGE_APPROVED: "approvedDateRange",
  DATE_RANGE_REGISTERED: "registeredDateRange",
  DATE_RANGE_METER_INSTALLED: "meterInstalledDateRange",
  DATE_RANGE_PANEL_INSTALLED: "panelsInstalledDateRange",
  PREVIOUS_DAYS_PLANNED: "plannedPreviousDays",
  NEXT_DAYS_PLANNED: "plannedNextDays",
  NO_DATE_PLANNED: "plannedNoDate",
  PREVIOUS_DAYS_APPROVED: "approvedPreviousDays",
  NEXT_DAYS_APPROVED: "approvedNextDays",
  NO_DATE_APPROVED: "approvedNoDate",
  PREVIOUS_DAYS_REGISTERED: "registeredPreviousDays",
  NEXT_DAYS_REGISTERED: "registeredNextDays",
  NO_DATE_REGISTERED: "registeredNoDate",
  PREVIOUS_DAYS_METER_INSTALLED: "meterInstalledPreviousDays",
  NEXT_DAYS_METER_INSTALLED: "meterInstalledNextDays",
  NO_DATE_METER_INSTALLED: "meterInstalledNoDate",
  PREVIOUS_DAYS_PANEL_INSTALLED: "panelsInstalledPreviousDays",
  NEXT_DAYS_PANEL_INSTALLED: "panelsInstalledNextDays",
  NO_DATE_PANEL_INSTALLED: "panelsInstalledNoDate",
  WITHOUT_PROJECT: "withoutProject",
  BREAKING_ISSUE: "breakingIssue",
  TAGS: "tagId",
  CLEARED_FOR_INSTALLATION: "clearedForInstallation",
  PREVIOUS_DAYS_CONTRACT_SENT: "contractSentPreviousDays",
  SCREENING_STATUS: "screeningStatus",
  CONVERSION_STATUS: "conversionStatus",
  PLANNING_STATUS: "planningStatus",
  INSTALLATION_STATUS: "installationStatus",
  ZM_CUSTOMER: "zmCustomer",
  ZM_PROJECT_MANAGER: "zmProjectManager",
  ZM_PROJECT_PLANNER: "zmProjectPlanner",
  ZM_FOREMAN: "zmForeman",
  PROJECT_CERTAINTY: "projectCertainty",
  PLANNING_TYPE: "planningType",
  INSTALLATION_PLANNED: "installationPlanned",
  INSTALLATION_APPROVED: "installationApproved",
  METER_REGISTERED: "meterRegistered",
  METER_PROCESS_READY: "meterProcessReady",
  PANEL_PROCESS_READY: "panelProcessReady"
};
