<template>
  <div id="legenda" class="pb-4">
    <v-checkbox
      class="calendar-legend-box"
      hide-details
      v-model="periods[0].type"
      :label="$t('office.labels.periods.screening')"
      value="screening"
      :color="periods[0].color"
    ></v-checkbox>

    <v-checkbox
      class="calendar-legend-box"
      hide-details
      v-model="periods[1].type"
      :label="$t('office.labels.periods.conversion')"
      value="conversion"
      :color="periods[1].color"
    ></v-checkbox>

    <v-checkbox
      class="calendar-legend-box"
      hide-details
      v-model="periods[2].type"
      :label="$t('office.labels.periods.planning')"
      value="planning"
      :color="periods[2].color"
    ></v-checkbox>

    <v-checkbox
      class="calendar-legend-box"
      hide-details
      v-model="periods[3].type"
      :label="$t('office.labels.periods.installation')"
      value="installation"
      :color="periods[3].color"
    ></v-checkbox>

    <v-checkbox
      class="calendar-legend-box"
      hide-details
      :label="$t('office.labels.periods.screeningStarted')"
      :color="events[0].color"
      v-model="events[0].type"
      value="screeningStartedAt"
      off-icon="mdi-checkbox-blank-outline"
      on-icon="mdi-clock-start"
    ></v-checkbox>

    <v-checkbox
      class="calendar-legend-box"
      hide-details
      v-model="events[1].type"
      :color="events[1].color"
      :label="$t('office.labels.periods.webinar')"
      value="webinarAt"
      off-icon="mdi-checkbox-blank-outline"
      on-icon="mdi-webrtc"
    ></v-checkbox>

    <v-checkbox
      class="calendar-legend-box"
      hide-details
      v-model="events[2].type"
      :label="$t('office.labels.periods.projectLaunch')"
      value="preAnnouncementAt"
      :color="events[2].color"
      off-icon="mdi-checkbox-blank-outline"
      on-icon="mdi-rocket-launch-outline"
    ></v-checkbox>

    <v-checkbox
      class="calendar-legend-box"
      hide-details
      v-model="events[3].type"
      :label="$t('office.labels.periods.contractSend')"
      value="contractsSentAt"
      :color="events[3].color"
      off-icon="mdi-checkbox-blank-outline"
      on-icon="mdi-email-fast-outline"
    ></v-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    periods: {
      type: Array
    },
    events: {
      type: Array
    }
  },

  data() {
    return {};
  }
};
</script>

<style>
#legenda {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.calendar-legend-box {
  margin-right: 10px;
}
</style>
