<template>
  <div
    id="calendar-rows"
    class="ganttContainer gantt-header-container"
    :style="determineHeaderRows"
  >
    <div v-bind:style="rows" class="calendar-column">
      <div
        v-for="(project, i) in projects"
        :key="i"
        class="d-flex justify-center align-center px-2"
      >
        <v-btn
          v-if="projectPeriods.find(period => period.row === i + 1)"
          icon
          x-small
          @click="handleEditableProjects(project.id, i)"
          :disabled="disabled(project, i)"
        >
          <v-icon color="green" :class="{ overlay: overlay(i) }">
            {{ icon(i) }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="selectedIndex === i"
          icon
          max-height="25"
          depressed
          x-small
          @click="undo()"
        >
          <v-icon>
            mdi-undo
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div
      v-for="(header, i) in filteredHeaders"
      :key="i"
      v-bind:style="rows"
      class="calendar-column"
    >
      <div v-for="(project, i) in projects" :key="i" class="hover">
        <router-link
          style="text-decoration: none; color: inherit;"
          :title="project['region']"
          target="_blank"
          :to="`/projecten/${project['id']}`"
          :class="{ overlay: editablePeriods.length && i !== selectedIndex }"
        >
          <div class="calendar-header caption px-2">{{ project[header] }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "underscore";

export default {
  props: {
    projects: {
      type: Array
    },
    editablePeriods: {
      type: Array
    },
    projectPeriods: {
      type: Array
    },
    headers: {
      type: Array
    }
  },

  data() {
    return {
      selectedIndex: -1,
      contentChanged: false,
      fixedPeriods: []
    };
  },

  watch: {
    editablePeriods: {
      deep: true,
      handler(val) {
        if (!val.length) {
          this.selectedIndex = -1;
          this.fixedPeriods = [];
        }

        if (!this.fixedPeriods.length) {
          this.fixedPeriods = val;
        }

        this.contentChanged = this.comparePeriods(this.fixedPeriods, val);
      }
    }
  },

  methods: {
    comparePeriods(obj1, obj2) {
      return _.isEqual(obj1, obj2);
    },
    undo() {
      this.$emit("update:editablePeriods", []);
    },

    handleEditableProjects(projectId, index) {
      if (index === this.selectedIndex) {
        this.$emit("updateProject", projectId);
        this.selectedIndex = -1;
        return;
      }

      this.selectedIndex = index;

      this.$emit("handleEditablePeriods", { index, projectId });
    },

    icon(i) {
      if (this.selectedIndex === i) {
        return "mdi-content-save";
      }

      return "mdi-pencil-outline";
    },

    disabled(project, index) {
      const zmCustomerCodes = ["ECOR", "ZOCO", "OVER"];
      const isZonmaatProject = zmCustomerCodes.includes(
        project.code.slice(0, 4)
      );

      if (
        !this.$roles(
          isZonmaatProject
            ? ["zonmaat_office"]
            : ["superuser", "project_manager"]
        )
      ) {
        return true;
      }

      return (
        (index !== this.selectedIndex && this.selectedIndex !== -1) ||
        (index === this.selectedIndex &&
          this.selectedIndex !== -1 &&
          this.contentChanged)
      );
    },

    overlay(index) {
      return index !== this.selectedIndex && this.selectedIndex !== -1;
    }
  },

  computed: {
    filteredHeaders() {
      return this.headers.filter(Boolean);
    },

    rows() {
      return {
        display: "grid",
        "grid-gap": "2px",
        "grid-template-rows": `repeat(${this.projects.length}, 30px)`
      };
    },
    determineHeaderRows() {
      return {
        display: "grid",
        "grid-template-columns": `50px repeat(${this.filteredHeaders.length}, 1fr)`
      };
    }
  }
};
</script>

<style lang="scss">
#calendar-rows {
  grid-area: 2 / 1 / 3 / 2;
  position: sticky;
  left: 0;
  z-index: 2;
}

.gantt-header-container {
  margin: 0;
  padding: 0;
  position: relative;
}

.calendar-column {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to top,
    rgba(189, 189, 189, 0.4) 6.25%,
    transparent 6.25%
  );
  background-size: 32px 32px;
  background-repeat: repeat;
  border-right: 3px solid rgba(189, 189, 189, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.overlay {
  opacity: 0.2;
  background-color: inherit;
}

.hover:hover {
  height: 100%;
  background-color: rgba(189, 189, 189, 0.4);
  display: flex;
  align-items: center;
}
</style>
